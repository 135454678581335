.ProfilePicture {
  display: grid;
  overflow: hidden;
  background: var(--color-frame);
  border-radius: var(--radius-avatar);
  place-items: center;

  &.isSmall {
    border-radius: var(--radius-avatarSmall);
  }

  .Icon {
    color: var(--color-gray-dark);
  }

  .Image {
    width: 100%;
    height: 100%;
    border-radius: var(--radius-avatar);
    object-fit: cover;
  }
}
