@import '@Assets/styles/abstracts';

.ProgressStepsItem {
  padding: px(2) px(5);
  border: 1px solid transparent;
  margin: 0;
  background-color: var(--color-frame);
  border-radius: 0;
  box-shadow: 0;
  cursor: pointer;

  @include transitions(
    (
      margin: md,
      padding: md,
      box-shadow: md,
      border-color: md,
      border-radius: md
    )
  );

  .Heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    .Icon {
      width: 20px;
    }

    &::after {
      position: absolute;
      bottom: -18px;
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--color-gray-light);
      content: '';
    }
  }

  .Body {
    .Description {
      margin-bottom: px(3);
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &:first-child {
    padding: px(4) px(5) px(2);
  }

  &:last-child {
    padding: px(2) px(5) px(4);

    .Heading {
      &::after {
        display: none;
      }
    }
  }

  &.isOpen {
    position: relative;
    padding: px(4);
    border-color: var(--color-gray);
    margin-right: px(-4);
    margin-left: px(-4);
    border-radius: var(--radius-blockElevated);
    box-shadow: var(--shadow-large);

    .Heading {
      margin-bottom: px(0.5);

      &::after {
        display: none;
      }
    }
  }

  &.isOpen,
  &.isChecked {
    cursor: auto;
  }

  @include media-breakpoint-down(sm) {
    padding: px(2) px(3);

    &.isOpen {
      padding: px(3);
      margin-right: px(-3);
      margin-left: px(-3);
    }
  }
}
