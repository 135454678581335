@import '@Assets/styles/abstracts';

.AccordionItemBase {
  border: solid 1px var(--color-gray-light);
  background-color: var(--color-gray-background);
  border-radius: var(--radius-accordion);

  @include transitions(
    (
      background-color: sm
    )
  );

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px(2) 0;
    border-bottom: solid 1px transparent;
    margin: 0 px(3);
    cursor: pointer;
    user-select: none;

    @include transitions(
      (
        border-color: sm
      )
    );
  }

  .Body {
    padding: px(2) px(3);

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &.isOpen {
    background-color: var(--color-frame);

    .Header {
      border-color: var(--color-gray-light);
    }
  }

  &.isSmall {
    border: 0;
    border-bottom: 1px solid var(--color-gray-light);
    background: transparent;
    border-radius: 0;

    .Header {
      padding: 0 0 px(1.5) 0;
      border-bottom: 0;
      margin: 0;


      > h4 {
        font-family: var(--font-large-fontFamily);
        font-size: var(--font-large-fontSize);
        font-weight: var(--font-bold-fontWeight);
      }
    }

    .Body {
      padding: 0 0 px(1) 0;
      margin: 0;
    }
  }
}
