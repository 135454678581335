@import '@Assets/styles/abstracts';

.Score {
  position: absolute;
  display: grid;
  animation: score-hover-in timing(xxl) $easing both;
  border-radius: 50%;
  box-shadow: var(--shadow-large);
  inset: 50% 0 0 50%;
  place-items: center;
  transform: translate(-50%, -50%);

  .Inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inset: 50% 0 0 50%;
    transform: translate(-50%, -50%);

    .Label,
    .Current,
    .Total {
      color: var(--color-font);
      line-height: 1;
      text-align: center;
    }

    .Label {
      margin: 0 0 px(1);
    }

    .Current {
      margin: 0 0 px(0.5);
    }

    .Total {
      opacity: 0.4;
    }

    &.isDark {
      .Label,
      .Current,
      .Total {
        color: var(--color-frame);
      }
    }
  }
}

@keyframes score-hover-in {
  from {
    box-shadow: none;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }

  to {
    box-shadow: var(--shadow-large);
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
