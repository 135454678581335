@import '@Assets/styles/abstracts';

.SliderPagination {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .Steps {
    display: flex;
    flex-direction: row;
    margin: 0 px(1.5);

    .Step {
      position: relative;
      width: 16px;
      height: 4px;
      background: var(--color-gray);
      border-radius: 4px;
      cursor: pointer;

      @include transitions((background-color: md));

      &:not(:last-child) {
        margin-right: px(0.5);
      }

      &:hover {
        background: var(--color-gray-dark);
      }

      &.isActive {
        background: var(--color-font);
      }

      @include transitions(
        (
          background-color: sm
        )
      );
    }
  }

  &.isDisabled {
    .Step {
      cursor: not-allowed;

      &:hover {
        background-color: var(--color-gray);
      }

      &.isActive {
        &::after {
          background-color: var(--color-gray-dark);
        }
      }
    }
  }
}
