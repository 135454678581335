@import '@Assets/styles/abstracts';

.Video {
  @include aspect(100%, '16:9');

  .Wrapper {
    overflow: hidden;
    background-position: center;
    background-size: cover;
    border-radius: var(--radius-video);
    cursor: pointer;
  }

  .Player {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .Icon {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    color: var(--color-frame);
    opacity: 1;
    transform: translate(-50%, -50%);

    @include transitions(
      (
        opacity: md
      )
    );
  }
}
