@import '@Assets/styles/abstracts';

.ProgressRow {
  display: flex;
  min-height: 42px;
  align-items: center;
  padding: px(1) px(3) px(1) px(1);
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: var(--radius-progressTheme);
  user-select: none;

  @include transitions(
    (
      background-color: md,
      border-color: md
    )
  );

  .Checkbox {
    margin-right: px(2);
    background-color: transparent;
    color: var(--color-gray);
  }

  .Wrapper {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .Title {
    width: 100%;
    flex-wrap: nowrap;
  }

  .Description {
    margin-right: px(3);
    color: var(--color-gray-dark);
    transform: translateY(1px);
    white-space: nowrap;
  }

  .Progress {
    width: 100%;
    max-width: 190px;
  }

  @include media-breakpoint-down(md) {
    .Progress {
      .ProgressBar {
        width: 100px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding-right: px(1);

    .Checkbox {
      margin-right: px(1);
    }

    .Wrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .Title {
      flex-direction: column;
      align-items: flex-start;

      p {
        margin-bottom: px(0);
      }
    }

    .Description {
      margin-bottom: px(0.5);
    }

    .Progress {
      width: 100%;
      max-width: none;
      margin-top: px(1);
    }
  }

  &.isSmall {
    padding: 0;

    .Wrapper {
      flex-direction: column;
      align-items: flex-start;

      .ChildWrapper {
        flex-wrap: nowrap;
      }
    }

    .Label {
      white-space: nowrap;
    }

    .Title > p {
      margin-bottom: px(0);
    }

    .Description {
      margin-bottom: px(0.5);
    }

    .Progress {
      width: 100%;
      max-width: 150px;
      margin-top: px(1);
    }
  }

  &.isDisabled {
    cursor: not-allowed;

    .Label {
      color: var(--color-gray-dark);
    }
  }

  &.hasHover {
    padding-left: px(3);

    &:hover {
      border-color: var(--color-gray-light);
    }
  }

  &.hasClick {
    cursor: pointer;

    &:not(.isChecked):hover {
      border-color: var(--color-success);
      background-color: var(--color-success-light);

      .Checkbox {
        background-color: transparent;
        color: var(--color-success);
      }
    }
  }

  &.isChecked {
    border-color: var(--color-success);
    background-color: var(--color-success-light);

    .Checkbox {
      background-color: var(--color-success);
      color: var(--color-frame);
    }
  }
}
