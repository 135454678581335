@import '@Assets/styles/abstracts';

.Motivator {
  position: relative;
  display: flex;
  padding: px(4);
  background: var(--color-background-dark);
  border-radius: 0 0 8px 8px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &.isVertical {
    flex-direction: column;
  }

  &::before {
    position: absolute;
    border-right: 8px solid transparent;
    border-bottom: 12px solid var(--color-background-dark);
    border-left: 8px solid transparent;
    content: '';
    inset: auto auto 100% px(4);
    transform: translateY(100%);

    @include transitions(
      (
        transform: xl
      )
    );
  }

  &.isOpen {
    &::before {
      transform: translateY(0);
    }
  }

  .Image {
    @include circle(48px);

    margin: 0 px(3) px(2) 0;
    object-fit: cover;

    @include media-breakpoint-down(sm) {
      margin: 0 0 px(2);
    }
  }

  .Name {
    margin: 0 0 px(0.5);
    font-weight: bold;
  }

  .Buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: px(2) 0 0;
    gap: px(1) px(3);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;

      .Button {
        margin: 0 0 px(1);
      }
    }
  }
}