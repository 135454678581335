@import '@Assets/styles/abstracts';

.Tooltip {
  position: absolute;
  z-index: 103;
  display: inline-flex;
  padding: px(0.25) px(1.5);
  animation: tooltip-fly-out timing(md) $easing both;
  background: var(--color-font);
  border-radius: var(--radius-toolTip);
  pointer-events: none;

  .Label {
    color: var(--color-frame);
  }

  .Pointer {
    position: absolute;
    top: 100%;
    border-top: 4px solid var(--color-font);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: '';
  }

  &.isLeft {
    .Pointer {
      left: px(1.5);
    }
  }

  &.isRight {
    .Pointer {
      left: calc(100% - (#{px(1.5)} + 8px));
    }
  }

  &.isCentered {
    .Pointer {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.isVisible {
    animation: tooltip-fly-in timing(md) $easing both;
    pointer-events: all;
  }
}

@keyframes tooltip-fly-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes tooltip-fly-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
