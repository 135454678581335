@import '@Assets/styles/abstracts';

.ProgressBar {
  position: relative;
  width: 100%;
  height: 6px;

  .Progress {
    position: absolute;
    z-index: 2;
    height: 6px;
    border-radius: 6px;
    inset: 0 auto 0 0;

    @include transitions(
      (
        width: lg
      )
    );
  }

  .Track {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 2px;
    background: var(--color-gray-light);
    border-radius: 6px;
    inset: 50% 0 0;
    transform: translateY(-50%);

    &.isFilled {
      height: 6px;
    }
  }

  &.isNotRounded {
    .Track {
      border-radius: 0;
    }

    .Progress {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}
