@import '@Assets/styles/abstracts';

.Cta {
  display: flex;
  justify-content: space-between;
  padding: px(1.5);
  background-color: var(--color-white);
  border-radius: var(--radius-sidebarCta);
  text-decoration: none;

  @include transitions(
    (
      background-color: lg,
      padding: lg
    )
  );

  .CollapsedIcon {
    animation: collapsedIcon-slide-in timing(lg) $easing;
  }

  .Content {
    opacity: 1;

    @include transitions(
      (
        opacity: lg
      )
    );
  }

  &.isCollapsed {
    padding: 0 px(1);

    .Content {
      opacity: 0;
    }
  }
}

@keyframes collapsed-icon-slide-in {
  from {
    width: 0;
  }

  to {
    width: 16px;
  }
}
