@import '@Assets/styles/abstracts';

.MessageItem {
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: px(2);
  border: solid 1px var(--color-gray-light);
  background-color: var(--color-gray-background);
  border-radius: var(--radius-inboxItem);
  cursor: pointer;

  @include transitions(
    (
      background-color: sm
    )
  );

  .Title {
    overflow: hidden;
    margin-bottom: px(0.5);
    text-overflow: ellipsis;
  }

  .TitleMessage {
    font-weight: var(--font-h4-fontWeight);
  }

  .Message {
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s $easing-both;
    white-space: inherit;
  }

  .Icon {
    transform: translateY(14px);
  }

  .Body {
    min-width: 0;
    flex: 1;
    margin-right: px(2);
  }

  .UnreadIndicator {
    position: absolute;
    top: px(1);
    right: px(1);
    width: 8px;
    height: 8px;
    background-color: var(--color-error);
    border-radius: 50%;
    opacity: 1;
    transition: opacity timing(md) $easing-reverse, transform timing(md) $easing-reverse;
  }

  @include media-breakpoint-down(sm) {
    .Logo {
      display: none;
    }
  }

  &:hover {
    background-color: var(--color-frame);
  }

  &.isConcatenated {
    white-space: nowrap;
  }

  &.isRead {
    background-color: var(--color-frame);

    .UnreadIndicator {
      opacity: 0;
      transform: translateY(15px);
    }
  }
}
