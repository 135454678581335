@import '@Assets/styles/abstracts';

.InputCheckbox .Item,
.InputCheckbox .Icon,
.InputCheckbox .Icon svg {
  @include transitions(
    (
      color: sm,
      border-color: sm,
      background-color: sm
    )
  );
}

.InputCheckbox {
  .Item {
    margin-bottom: px(1.5);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .Input {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;

    &:hover {
      .Icon {
        border-color: var(--color-success);

        svg {
          color: var(--color-success);
        }
      }
    }
  }

  .Label {
    margin: 0;
    color: var(--color-font);
    font-size: var(--font-medium-fontSize);
    line-height: 1;
    user-select: none;

    a {
      color: var(--color-font);
      text-decoration: underline;
    }
  }

  .Icon {
    @include circle(16px);

    border: 1px solid var(--color-gray);
    margin-right: px(1.5);
    border-radius: var(--radius-inputText); // Uncircle the circulair circle
    color: var(--color-gray-light);
  }

  .Input.isDisabled {
    cursor: not-allowed;

    .Label {
      border-color: var(--color-gray);
      color: var(--color-gray);
    }

    .Icon {
      border-color: var(--color-gray);

      svg {
        color: var(--color-gray-light);
      }
    }
  }

  input:focus + .Icon {
    outline: 1px solid var(--color-gray-light);
  }

  &.isError {
    .Input {
      .Icon {
        border-color: var(--color-error);

        svg {
          color: var(--color-error);
        }
      }
    }
  }

    .Input.isChecked {
      .Label {
        color: var(--color-font);
      }

      .Icon {
        border-color: var(--color-success);
        background-color: var(--color-success);
        color: var(--color-frame);

        svg {
          color: var(--color-frame);
        }
      }
    }
}
