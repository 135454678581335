/* stylelint-disable selector-class-pattern */
@import '@Assets/styles/abstracts';

:global .rc-select {
  &__control {
    display: flex;
    width: 100%;
    padding: px(1.5) px(2);
    border: 1px solid var(--color-gray);
    background-color: var(--color-frame);
    border-radius: var(--radius-inputText);
    color: var(--color-font);
    cursor: pointer !important;
    font-family: var(--font-family-base);

    div {
      padding: 0;
      margin: 0;
    }

    @include transitions(
      (
        border: sm,
        border-color: sm,
        outline: sm,
      )
    );
  }

  &__menu-portal {
    z-index: 101 !important;
  }

  &__singleValue,
  &__placeholder {
    font-family: var(--font-family-base);
    font-size: var(--font-normal-fontSize);
    font-weight: var(--font-normal-fontWeight);
    line-height: var(--font-normal-lineHeight);
  }

  &__placeholder {
    color: var(--color-gray-dark) !important;
  }

  &__singleValue {
    color: var(--color-font);
  }
}

.Option,
.Option .Checkmark {
  @include transitions(
    (
      background-color: sm,
      color: sm
    )
  );
}

.Option {
  display: flex;
  align-items: center;
  padding: px(1) px(1.5);
  color: var(--color-font);
  cursor: pointer;

  &:focus {
    display: none;
  }

  .Checkmark {
    @include circle(24px);

    color: var(--color-gray);

    svg {
      width: 8px;
    }
  }

  .Label {
    margin-left: px(1.5);
  }

  &.isSelected {
    background-color: var(--color-success-light);

    .Checkmark {
      background-color: var(--color-success);
      color: var(--color-frame);
    }
  }

  &.isDisabled {
    color: var(--color-gray);
    cursor: not-allowed;
  }

  &:not(.isDisabled):not(.isSelected) {
    &:hover,
    &.isFocused {
      background-color: var(--color-success-light);

      .Checkmark {
        color: var(--color-success);
      }
    }
  }
}

.Seperator {
  width: 100%;
  height: 1px;
  background-color: var(--color-gray-light);

  &:last-child {
    display: none;
  }
}

.NoOptions {
  padding: px(1) px(1.5);
}

.InputSelect {
  &.isError {
    :global .rc-select {
      &__control {
        border-color: var(--color-error) !important;
        background-color: var(--color-error-light) !important;
      }

      &__singleValue {
        color: var(--color-error);
      }
    }
  }

  &.isDisabled {
    pointer-events: all;

    :global .rc-select {
      &__control {
        border-color: var(--color-gray) !important;
        background-color: var(--color-gray-background) !important;
        cursor: not-allowed !important;
      }

      &__singleValue {
        color: var(--color-gray-dark);
      }
    }
  }
}
