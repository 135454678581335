@import '@Assets/styles/abstracts';

.PopupMessage {
  position: fixed;
  z-index: 98;
  right: px(4);
  bottom: px(4);
  display: flex;
  width: 287px;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;

  .Block {
    animation: popupmessage-fade-out timing(md) $easing-reverse both;

    .Name {
      margin-bottom: px(0.5);
      font-weight: var(--font-bold-fontWeight);
    }

    .Children {
      margin-top: px(2);
    }
  }

  .ImageContainer {
    position: relative;
    width: 72px;
    height: 72px;
    margin-top: px(2);
    cursor: pointer;
    pointer-events: all;

    .Image {
      overflow: hidden;
      border-radius: 50%;
    }

    .Notification {
      position: absolute;
      top: 0;
      right: 0;
      width: 17px;
      height: 17px;
      border: 3px solid var(--color-background);
      background-color: var(--color-green);
      border-radius: 50%;
    }
  }

  &.isOpen {
    pointer-events: all;

    .Block {
      animation: popupmessage-fade-in timing(md) $easing both;
      pointer-events: all;
    }
  }
}

@keyframes popupmessage-fade-in {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popupmessage-fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(50px);
  }
}
