@import '@Assets/styles/abstracts';

.Modal {
  position: fixed;
  z-index: 101;
  animation: modal-fade-out timing(md) $easing both;
  inset: 0;

  .Card {
    position: absolute;
    overflow: hidden;
    width: 410px;
    max-height: 90vh;
    animation: modal-card-slide-out timing(md) $easing both;
    background-color: var(--color-frame);
    border-radius: var(--radius-modal);
    box-shadow: var(--shadow-small);
    inset: 50% auto auto 50%;
    overflow-y: auto;

    .Close {
      position: absolute;
      inset: px(4) px(4) auto auto;

      @include media-breakpoint-down(sm) {
        inset: px(2) px(2) auto auto;
      }
    }

    .Content {
      position: relative;
      padding: px(4) px(4) px(4);

      .Title {
        margin: 0 px(4) px(2) 0;
      }
    }

    @include media-breakpoint-down(sm) {
      width: calc(100% - #{px(2)});
      min-width: 0;

      .Content {
        padding: px(2) px(6) px(2) px(2);
      }
    }
  }

  .Overlay {
    position: absolute;
    background-color: var(--color-font);
    inset: 0;
    opacity: 0.4;
  }

  &.isOpen {
    animation: modal-fade-in timing(md) $easing both;

    .Card {
      animation: modal-card-slide-in timing(md) $easing both;
    }
  }
}

@keyframes modal-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes modal-card-slide-in {
  from {
    transform: translate(-50%, calc(-50% - 25px));
  }

  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes modal-card-slide-out {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, calc(-50% - 25px));
  }
}
