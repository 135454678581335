@import '@Assets/styles/abstracts';

.ButtonIcon {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration-color: var(--color-font);
  user-select: none;

  .Icon {
    display: grid;
    width: 16px;
    height: 16px;
    background: var(--color-gray-light);
    border-radius: var(--radius-buttonIcon);
    color: var(--color-gray-dark);
    place-items: center;

    @include transitions(
      (
        background: sm,
        color: sm,
        transform: sm
      )
    );
  }

  .Label {
    flex: 1;
    margin: 0 0 0 px(0.75);
  }

  &:hover,
  &.isHovering {
    &:not(.isDisabled) {
      .Icon {
        background: var(--color-gray-dark);
        color: var(--color-frame) !important;
        transform: scale(1.2);
      }
    }
  }

  &.isDisabled {
    cursor: not-allowed;

    .Label {
      color: var(--color-gray-dark);
    }
  }

  &.isReversed {
    flex-direction: row-reverse;

    .Label {
      margin: 0 px(0.75) 0 0;
    }
  }
}
